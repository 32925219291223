<template>
  <v-card class="my-8">
    <v-card-text>
      <multipartUploadFile
        ref="multipartUploadFile"
        object-acl="public-read"
        :target-uid="() => 'demo'"
        :validExtension="['mp4', 'webm', 'mkv', 'avi', 'mov']"
        :validFileType="['video/']"
        :bucket="mediaUploadBucket"
        :region="mediaUploadRegion"
        :scope="mediaUploadScope"
        :setFileObjectKey="setFileObjectKey"
        :onUploadCompletedCallback="onUploadCompleted"
        :onFileChangeCallback="onFileChange"
        :onFailureCallback="onFailure"
      ></multipartUploadFile>
    </v-card-text>

    <v-card-text>
      <v-btn
        color="primary"
        class="mr-4 mb-4"
        @click="listMultipartUploads"
      >
        listMultipartUploads
      </v-btn>

      <v-btn
        color="primary"
        class="mr-4 mb-4"
        @click="abortAllUnfinishedUploads"
      >
        abortAllUnfinishedUploads
      </v-btn>
    </v-card-text>

    <v-card-text v-if="unfinishedMultipartUploads">
      <div class="font-weight-bold mb-2">unfinishedMultipartUploads</div>
      <div>{{unfinishedMultipartUploads}}</div>
    </v-card-text>

    <v-card-text>
      <v-text-field
        dense
        outlined
        class="mb-4"
        v-model="objectKey"
      ></v-text-field>
      <v-btn
        color="primary"
        class="mr-4 mb-4"
        @click="getObjectAttributes"
      >
        getObjectAttributes
      </v-btn>

      <div class="font-weight-bold mb-2">objectData</div>
      <div>{{objectData}}</div>
    </v-card-text>
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  metaInfo() {
    return {
      title: 'Multipart Upload',
    }
  },
  data: () => ({
    objectKey: null,
    unfinishedMultipartUploads: null,
    objectData: null,
  }),
  methods: {
    async listMultipartUploads() {
      this.unfinishedMultipartUploads = await this.$refs.multipartUploadFile.listMultipartUploads()
    },
    async abortAllUnfinishedUploads() {
      await this.$refs.multipartUploadFile.abortAllUnfinishedUploads()
    },
    onFileChange(file) {

    },
    onUploadCompleted(data) {
      console.warn('onUploadCompleted', data, data.fileObjectKey)
    },
    onFailure(data) {
      console.warn('onFailure', data)
    },
    setFileObjectKey(extension, fileUid) {
      return `${this.mediaUploadScope}/static-video/${fileUid}.${extension}`
    },
    async getObjectAttributes() {
      console.warn('objectData', this.objectKey)
      this.objectData = await this.$refs.multipartUploadFile.getObjectAttributes(this.objectKey)
    },
  },
  computed: {
    env() {
      return this.$store.getters['base/eagleEnv']
    },
    mediaUploadScope() {
      return this.env.mediaUploadScope
    },
    mediaUploadBucket() {
      return this.env.mediaUploadBucket
    },
    mediaUploadRegion() {
      return this.env.mediaUploadRegion
    },
  },
  components: {
    multipartUploadFile: () => import('components/multipartUploadFile/multipartUploadFile.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
